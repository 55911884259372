import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import image2 from "../Images/movie-inside3.png";
import video from "../Images/portfolio1.mp4";
import Arrow from "../Images/left.png";
const PageThree = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger the animation when the component mounts
    setAnimate(true);
  }, []);
  const captions = [
    "A short biopic of Clint Thambi",
  ];
  const castMembers = [

    "Juby Mathew ",
    "Clint Thambi ",
  ];
  const crewMembers = [
  
    "Director – Clint Thambi ",
    "Screenplay – Clint Thambi ",
    "Producer – Juby Mathew ",
    "Director of Photography – Clint Thambi ",
    "Assistant Director – Nishan Lama ",
    "Cinematographer – Nishan Lama " 
];

  return (
    <>
      <Container className="parallexScroll">
        <div className={`gallery ${animate ? "zoom-in" : ""}`}>
          {[image2, video, image2,image2].map((item, index) => (
            <div className="pinned-image" key={index}>
              {index === 1 ? (
                <video
                  className="video-responsive"
                  loop
                  autoPlay
                  playsinline
                  muted={true} // Allows the video to play with sound
                  controls // Optional, if you want to give users play/pause controls
                  style={{ cursor: "pointer" }}
                >
                  <source
                    src="../Videos/movie-3.mp4"
                    type="video/mp4"
                  />
                </video> // Video element for second item
              ) : (
                <img
                  src={item}
                  alt={`Item ${index + 1}`}
                  className="img-fluid w-100"
                />
              )}
               {index !== 1 && index !== 2 && (
                <div className="caption">{captions[index]}</div>
              )}
              {/* Third Slide: Cast Section */}
              {index === 2 && (
                <div className="caption">
                  
                  <div className="castCrewContainer">
                    {/* Cast Section */}
                    <div className="castSection">
                    
                    </div>

                    {/* Crew Section */}
                    <div className="crewSection">
                    <h6>Cast</h6>
                      {castMembers.map((member, idx) => (
                        <h5 key={idx}>{member}</h5>
                      ))}
                    </div>
                </div>
                </div>
              )}{index === 3 && (
                <div className="caption">
                  
                  <div className="castCrewContainer">
                   {/* Crew Section */}
                  
                    <div className="castSection">
                    </div>
                    <div className="crewSection">
                    <h6>Crew</h6>
                      {crewMembers.map((member, idx) => (
                        <h5 key={idx}>{member}</h5>
                      ))}
                    </div>
                </div>
                
                </div>
              )}
             
            </div>
          ))}
        </div>
        <div
        className="back-arrow"
        onClick={() => window.history.back()} // Navigate back on click
      >
       <img src={Arrow} alt="Placeholder"/> {/* Unicode left arrow */}
      </div>
      </Container>
    </>
  );
};

export default PageThree;
